import consumer from "./consumer"

consumer.subscriptions.create({ channel: 'ProgramsChannel', program_id: window.dataLayer[0].programId }, {
  received: function(data) {
    if (data.download_url) {
      $("#batch-download-modal").find(".modal-title").text("Your reports bundle is ready");
      $("#batch-download-spinner").addClass("d-none");
      $("#batch-download-link").attr("href", data.download_url);
      $("#batch-download-success").removeClass("d-none");
    } else if (!data.download_url) {
      $("#batch-download-modal").find(".modal-title").text("There was an error generating your reports bundle");
      $("#batch-download-spinner").addClass("d-none");
    }
    if (data.failed_documents && data.failed_documents.length > 0) {
      let failedDocHtmlList = data.failed_documents.map(doc => `<li>${doc}</li>`).join("");
      $("#batch-download-error-message").html(`These reports were not generated:<ul class="mt-2">${failedDocHtmlList}</ul>`).removeClass("d-none");
    }
  },
});
